<template>
    <div class="text-gray-500 max-h-screen min-h-screen bg-gray-800 z-40 flex items-center justify-center">
        <loading :active.sync="isLoading" 
            :is-full-page="true">
        </loading>
        <div class="w-full">
            <div class="mx-auto text-center border-4 border-gray-500 w-24 h-24 rounded-full mb-5 flex items-center justify-center">
                <i class="icon-reading icon-3x"></i>
            </div>
            <div class="text-center text-lg">MASUK KE AKUN ANDA</div>
            <div class="text-center mb-16">Masukkan kredensial Anda di bawah ini</div>
            <form @submit.prevent="onSubmit" class="w-full px-10">
                <div class="w-full mb-5">
                    <input v-model="form.email" type="email" class="h-9 w-full rounded-sm bg-gray-900 border border-gray-700 px-3" placeholder="email">
                </div>
                <div class="w-full">
                    <input v-model="form.password" type="password" class="h-9 w-full rounded-sm bg-gray-900 border border-gray-700 px-3" placeholder="password">
                </div>
                <div class="w-full mt-10">
                    <button class="w-full bg-green-600 text-gray-300 py-2 rounded-sm">Masuk</button>
                </div>
            </form>
            <div class="text-center mt-5">
                <a href="#">Lupa Password ?</a>
            </div>
            <div class="text-center mt-16">
                <a href="#">DWITAMAELEKTRINDO</a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            form: {
                email: '',
                password: '',
                device_name: 'mobile'
            },
            error: {
                email: '',
                password: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            hasError: 'auth/error'
        })
    },
    methods: {
        ...mapActions({
            login: 'auth/login'
        }),
        onSubmit () {
            if(!this.form.email) {
                this.error.email = 'Email belum diisi'
            }
            if(!this.form.password) {
                this.error.password = 'Sandi belum diisi'
            }
            if(this.form.email && this.form.password) {
                this.signIn()
            }
        },
        signIn () {
            this.isLoading = true
            this.login(this.form)
            .then(() => {
                this.isLoading = false
                if(!this.hasError) {
                    this.$router.push('/')
                }
            })
        }
    }
}
</script>